import { Component, Vue } from "vue-property-decorator"

@Component
export default class TabMixin extends Vue {
  get responsiveTabHeight(): string {
    const bp = this.$vuetify.breakpoint
    let height = 0
    if (bp.lgAndDown) {
      height = 54
    } else {
      height = 80
    }
    return `${height}px`
  }
}
